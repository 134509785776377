<template>
  <div :class="classes">
    <label class="label-name">{{ label }}</label>
    <mkp-input
      v-model="name"
      type="text"
      autocomplete="on"
      :placeholder="$t(placeholder)"
      class="name-input"
      @input="v.$touch()"
    />
    <div v-if="v.$error">
      <div v-if="v.required != undefined && !v.required" class="error-name">
        {{ $t("forms.components.name.errors.required") }}
      </div>
    </div>
  </div>
</template>

<script>
import MkpInput from "../../../simple/input/mkp-input";
import "./inputsform.scss";
export default {
  name: "MkpNameComponent",
  components: { MkpInput },
  props: {
    placeholder: {
      type: String,
      default: "forms.components.name.placeholder"
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    }
  },
  computed: {
    name: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    classes() {
      return {
        errorRed: this.v.$error
      };
    }
  }
};
</script>
