<template>
  <form novalidate class="form-signup" @submit.prevent="submit">
    <mkp-name-component
      v-model="form.lastName"
      :label="$t('forms.singup.form.lastname.label')"
      :v="$v.form.lastName"
      placeholder="forms.singup.form.lastname.placeholder"
      class="container-input"
    />

    <mkp-name-component
      v-model="form.firstName"
      :label="$t('forms.singup.form.firstname.label')"
      :v="$v.form.firstName"
      placeholder="forms.singup.form.firstname.placeholder"
      class="container-input"
    />

    <mkp-name-component
      v-model="form.enterprise"
      :label="$t('forms.singup.form.enterprise.label')"
      :v="$v.form.enterprise"
      placeholder="forms.singup.form.enterprise.placeholder"
      class="container-input"
    />

    <mkp-email-component
      v-model="form.email"
      :label="$t('forms.singup.form.email.label')"
      :v="$v.form.email"
      placeholder="forms.singup.form.email.placeholder"
      class="container-input"
    />

    <mkp-password-component
      v-model="form.password"
      :label="$t('forms.singup.form.password.label')"
      :v="$v.form.password"
      placeholder="forms.singup.form.password.placeholder"
      class="container-input"
    />

    <mkp-password-component
      v-model="form.confirmPassword"
      :label="$t('forms.singup.form.confirmPassword.label')"
      :v="$v.form.confirmPassword"
      placeholder="forms.singup.form.confirmPassword.placeholder"
      class="container-input"
    />

    <mkp-button class="btn-signup" @click="submit">
      {{ $t("forms.singup.form.button") }}
    </mkp-button>

    <mkp-link class="link-signin" @click="changeStep('connection')">
      {{ $t("forms.singup.form.links.connection") }}
    </mkp-link>
  </form>
</template>

<script>
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import MkpNameComponent from "../../inputsForm/mkp-name-component";
import MkpEmailComponent from "../../inputsForm/mkp-email-component";
import MkpPasswordComponent from "../../inputsForm/mkp-password-component";
import MkpButton from "../../../../simple/button/mkp-button";
import MkpLink from "../../../../simple/link/mkp-link";
import "./steps-scss/mkp-form-signup.scss";

export default {
  name: "MkpFormSingup",
  components: {
    MkpLink,
    MkpButton,
    MkpPasswordComponent,
    MkpEmailComponent,
    MkpNameComponent
  },
  data: () => {
    return {
      url: "",
      form: {
        lastName: null,
        firstName: null,
        enterprise: null,
        email: null,
        password: "",
        confirmPassword: ""
      }
    };
  },
  validations: {
    form: {
      lastName: {
        required
      },
      firstName: {
        required
      },
      enterprise: {
        required
      },
      email: {
        email,
        required
      },
      password: {
        required,
        minLength: minLength(8)
      },
      confirmPassword: {
        sameAsPassword: sameAs("password")
      }
    }
  },
  methods: {
    changeStep(step) {
      this.$store.dispatch("setModalStep", step);
    },
    submit() {
      this.$v.form.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return;
      // to form submit after this
      this.$emit("SingUp", this.form);
    }
  }
};
</script>

<style scoped></style>
